import {Box, Card, Checkbox, Table, TableBody, TableHead, TableRow, Tooltip, Typography} from "@mui/material";
import {observer} from "mobx-react";
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    AddButton,
    AuthorizePermission,
    Cell,
    ConfirmationForm,
    EmptyBox,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    Page,
    PaginationLocal,
    Progress,
    Search
} from '../../../components';
import {CopyToClipboard} from "../../../components/CopyToClipboard";
import {MenuComponent} from "../../../components/MenuComponent";
import {MenuItemComponent} from "../../../components/MenuItemComponent";
import {NavLink} from "../../../components/NavLink";
import {SeverityPill, SeverityPillColor} from "../../../components/severity-pill";
import {DefaultMultiSelect} from "../../../components/shared/components/DefaultMultiSelect";
import {AllPermissions, PersonStatusesNew} from "../../../core/const";
import {CloudUpload as CloudUploadIcon} from '../../../icons/cloud-upload';
import {DeleteIcon} from "../../../icons/deleteIcon";
import {Scrollbar} from "../../../scrollbar";
import {JobsTableStore} from "../../../stores/management/persons/tabs/jobs/JobsTableStore";
import {personStatusHandler} from "../Persons";
import {JobCreator} from "./JobCreator";
import {JobDetails} from "./JobDetails";
import {callTheProcessingFunc} from "../../../stores";

interface Props {
    store: JobsTableStore;
    double?: boolean;
    style?: React.CSSProperties;
}

export const Jobs = observer(({store, double = false, style}: Props) => {
    const {t} = useTranslation();
    const [openDetails, setOpenDetails] = useState<string | null>(null);

    useEffect(() => {
        if (!!double) {
            store.size = 10;
        } else {
            store.size = 25;
        }
    }, []);

    const handleOpenDetails = (productId: string): void => {
        setOpenDetails((prevValue) => (prevValue === productId ? null : productId));
    };

    if (!!double) {
        return (
            <>
                <Card>
                    <Scrollbar>
                        <Table sx={{minWidth: 900}}>
                            <TableHead>
                                <TableRow>
                                    <HCell width='2%' testid="jobs-expand"></HCell>
                                    <HCell orderBy='id' store={store} width='10%' testid="jobs-Id">Id</HCell>
                                    <HCell orderBy='organizationName' store={store} width='15%'
                                           testid="jobs-organizationName">{t('table.organization')}</HCell>
                                    <HCell orderBy='orgType' store={store} width='12%'
                                           testid="jobs-orgType">{t('table.type')}</HCell>
                                    <HCell orderBy='post' width='12%' store={store}
                                           testid="jobs-post">{t('table.post')}</HCell>
                                    <HCell orderBy='status' width='10%' store={store}
                                           testid="jobs-status">{t('table.status')}</HCell>
                                    <HCell orderBy='modified' store={store} width='11%'
                                           testid="jobs-modified">{t('table.modified')}</HCell>
                                    <HCell orderBy='address' width='15%' store={store}
                                           testid="jobs-address">{t('table.address')}</HCell>
                                    <HCell store={store} width='8%'
                                           testid="jobs-primaryJob">{t('table.primaryJob')}</HCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    store.items.length > 0
                                        ? store.items.map(item => {
                                            const open = item.id === openDetails;
                                            return (
                                                <Fragment key={item.id}>
                                                    <TableRow
                                                        hover
                                                        key={item.id}
                                                        onClick={() => store.current = item}
                                                    >
                                                        <Cell>
                                                            <Box sx={{maxWidth: '2px', minHeight: 48}}/>
                                                        </Cell>
                                                        <Cell testid="jobs-id">
                                                            <Typography variant="subtitle2"
                                                                        style={{display: "flex", alignItems: 'center'}}>
                                                                {`${item.dto.organization.id.substring(0, 11)}...`}
                                                                <CopyToClipboard copyText={item.dto.organization.id}/>
                                                            </Typography>
                                                        </Cell>
                                                        <Cell style={{maxWidth: 200}} titleText={item.organizationName}
                                                              testid="jobs-organizationName">
                                                            <NavLink to={`/organizations/${item.dto.organization.id}`}
                                                                     target="_blank">
                                                                <Tooltip title={item.organizationName}>
                                                                    <Typography variant="subtitle2"
                                                                                style={{
                                                                                    maxWidth: 200,
                                                                                    display: '-webkit-box',
                                                                                    WebkitLineClamp: 2,
                                                                                    WebkitBoxOrient: 'vertical',
                                                                                    overflow: 'hidden'
                                                                                }}
                                                                    >
                                                                        {item.organizationName}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </NavLink>
                                                        </Cell>
                                                        <Cell testid="jobs-orgType">
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body2">
                                                                {item.dto.organization?.typeOrg?.nameRus}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell titleText={item.dto.post.nameRus} testid="jobs-post">
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body2">
                                                                {item.dto.post.nameRus}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell testid="jobs-status">
                                                            {("status" in item.dto) &&
                                                                <SeverityPill
                                                                    color={personStatusHandler(item.dto.status?.nameRus || '') as SeverityPillColor}>
                                                                    <Tooltip
                                                                        title={item.dto.status?.nameRus}>
                                                                        <Typography variant="caption" align={'left'} sx={{
                                                                            maxWidth: 130,
                                                                            textOverflow: 'ellipsis',
                                                                            overflow: 'hidden',
                                                                        }}>
                                                                            {item.dto.status?.nameRus}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                </SeverityPill>
                                                            }
                                                        </Cell>
                                                        <Cell testid="jobs-modified">
                                                            {item.date}
                                                        </Cell>
                                                        <Cell titleText={item.address} testid="jobs-address">
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body2">
                                                                {item.address}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell align={'center'} testid="jobs-primaryJob">
                                                            {!item.isTypeDepartment &&
                                                                <Checkbox
                                                                    checked={store.store.primaryJob.value === item.id}
                                                                    onChange={(e) => {
                                                                        if (store.store.primaryJob.value === item.id) return
                                                                        store.store.primaryJob.value = item.id;
                                                                        store.store.save()
                                                                    }}
                                                                    disabled={true}
                                                                />
                                                            }
                                                        </Cell>
                                                    </TableRow>
                                                    {
                                                        open && (
                                                            <ExpandRow key={`${item.id}_`} item={item}>
                                                                <JobDetails job={item}/>
                                                            </ExpandRow>)
                                                    }
                                                </Fragment>)
                                        })
                                        : <Cell colSpan={9}>
                                            <EmptyBox/>
                                        </Cell>
                                }
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </Card>
                {store.items.length > 0 && <PaginationLocal store={store}/>}
                {
                    !store.items.length && !!store.loading && <Box
                        sx={{
                            flexGrow: 1,
                            py: 3,
                        }}
                    >
                        <Progress store={store}/>
                    </Box>
                }
            </>
        );
    }
    return (
        <>
            {
                store.current
                    ? (
                        <ExpertDialog
                            title={t('common.delete')}
                            open={store.deleteDialogState}
                            onClose={() => store.current ? store.deleteDialogState = false : false}
                        >
                            <ConfirmationForm
                                disabled={store.current.processing}
                                onSubmit={async () => await callTheProcessingFunc(store!.current!, (e) => store.current!.remove(), (e) => store.current!.store.store.loadPerson())()}
                                onClose={() => store.current ? store.deleteDialogState = false : false}
                            >
                                {store.current.associatedDepJob ?
                                    <>
                                        {`${t('common.associatedDeleteConfirm')} "${store.current.associatedDepJob.organizationName} ${store.current.associatedDepJob.dto.post.nameRus}". ${t('common.deleteConfirm')} "${store.current.organizationName} ${store.current.dto.post.nameRus}"?`}
                                    </>
                                    :
                                    <>
                                        {t('common.deleteConfirm')} "{store.current.organizationName} {store.current.dto.post.nameRus}"?
                                    </>
                                }

                            </ConfirmationForm>
                        </ExpertDialog>
                    )
                    : ''
            }
            <Page style={style}>
                <>
                    <ExpertDialog
                        title={t('create.job')}
                        open={store.creator.Open}
                        onClose={() => store.creator.Open = false}
                    >
                        <JobCreator store={store.creator}/>
                    </ExpertDialog>

                    <Box sx={{mt: 1}}
                         style={{display: 'flex', justifyContent: 'space-between'}}
                    >
                        <Search
                            value={store.search.value}
                            onChange={t => store.search.value = t}
                            style={{width: '87%'}}
                            testid="jobs-fio"
                        />
                        <AuthorizePermission permissions={AllPermissions.person.personjobs.update}>
                            <AddButton onClick={() => store.creator.Open = true} testid='jobs-create-btn'
                                       disabled={store.store.disable}/>
                        </AuthorizePermission>
                    </Box>

                    <Box
                        sx={{mt: 1}}
                        style={{display: 'flex', justifyContent: 'space-between'}}
                    >
                        <DefaultMultiSelect store={store.organizationFilter}
                                            title={t('common.organization')}
                                            nameExp={(item) => item.names[0].name as string}
                                            style={{width: '24%'}}
                                            testid="jobs-organizationFilter"
                        />
                        <DefaultMultiSelect store={store.postFilter}
                                            title={t('common.position')}
                                            style={{width: '24%'}}
                                            testid="jobs-postFilter"
                        />
                        <DefaultMultiSelect store={store.statusFilter}
                                            nameExp={(dto) => dto.id === PersonStatusesNew.Double ? '' : dto.nameRus}
                                            title={t('common.status')}
                                            style={{width: '24%'}}
                                            testid="jobs-statusFilter"
                        />
                        <DefaultMultiSelect store={store.tagsFilter}
                                            title={t('common.tags')}
                                            style={{width: '24%'}}
                                            testid="jobs-tagsFilter"
                        />
                    </Box>
                </>
                <Card>
                    <Scrollbar>
                        <Table sx={{minWidth: 1050}}>
                            <TableHead>
                                <TableRow>
                                    <HCell width='2%' testid="jobs-expand"></HCell>
                                    <HCell orderBy='id' store={store} testid="jobs-Id">Id</HCell>
                                    <HCell orderBy='organizationName' store={store}
                                           testid="jobs-organizationName">{t('table.organization')}</HCell>
                                    <HCell orderBy='orgType' store={store}
                                           testid="jobs-orgType">{t('table.type')}</HCell>
                                    <HCell orderBy='post' store={store} testid="jobs-post">{t('table.post')}</HCell>
                                    <HCell orderBy='status' store={store}
                                           testid="jobs-status">{t('table.status')}</HCell>
                                    <HCell store={store} testid="jobs-tags"
                                           style={{padding: '0 16px 0 0'}}>{t('common.tags')}</HCell>
                                    <HCell orderBy='created' store={store}
                                           testid="jobs-created">{t('table.createdDate')}</HCell>
                                    <HCell orderBy='modified' store={store}
                                           testid="jobs-modified">{t('table.modified')}</HCell>
                                    <HCell orderBy='address' store={store}
                                           testid="jobs-address">{t('table.address')}</HCell>
                                    <HCell store={store} width='5%'
                                           testid="jobs-primaryJob">{t('table.primaryJob')}</HCell>
                                    {
                                        !store.isDisable && <HCell store={store} width='5%' style={{padding: '0 15px'}}
                                                                   testid="jobs-action">{t('table.action')}</HCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    store.items.length > 0
                                        ? store.items.map(item => {
                                            const open = item.id === openDetails;
                                            return (
                                                <Fragment key={item.id}>
                                                    <TableRow
                                                        hover
                                                        key={item.id}
                                                        onClick={() => store.current = item}
                                                    >
                                                        <ExpandButton item={item} isOpen={open} testid="jobs"
                                                                      onClick={async () => {
                                                                          if (!store.store.isDouble || !store.store.isDead) {
                                                                              handleOpenDetails(item.id);
                                                                          }
                                                                      }}
                                                        />
                                                        <Cell testid="organizationTable-id">
                                                            <Typography variant="subtitle2"
                                                                        style={{display: "flex", alignItems: 'center'}}>
                                                                {`${item.dto.organization.id.substring(0, 11)}...`}
                                                                <CopyToClipboard copyText={item.dto.organization.id}/>
                                                            </Typography>
                                                        </Cell>
                                                        <Cell style={{minWidth: 180}} titleText={item.organizationName}
                                                              testid="jobs-organizationName">
                                                            <NavLink to={`/organizations/${item.dto.organization.id}`}
                                                                     target="_blank">
                                                                <Tooltip title={item.organizationName}>
                                                                    <Typography variant="subtitle2"
                                                                                style={{
                                                                                    maxWidth: 180,
                                                                                    display: '-webkit-box',
                                                                                    WebkitLineClamp: 2,
                                                                                    WebkitBoxOrient: 'vertical',
                                                                                    overflow: 'hidden'
                                                                                }}
                                                                    >
                                                                        {item.organizationName}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </NavLink>
                                                        </Cell>
                                                        <Cell testid="jobs-orgType">
                                                            <Tooltip title={item.dto.organization?.typeOrg?.nameRus}>
                                                                <Typography variant="subtitle2"
                                                                            style={{
                                                                                maxWidth: 130,
                                                                                display: '-webkit-box',
                                                                                WebkitLineClamp: 2,
                                                                                WebkitBoxOrient: 'vertical',
                                                                                overflow: 'hidden'
                                                                            }}
                                                                >
                                                                    {item.dto.organization?.typeOrg?.nameRus}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Cell>
                                                        <Cell style={{minWidth: 160}} testid="jobs-post">
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body2">
                                                                {item.dto.post.nameRus}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell testid="jobs-status">
                                                            <SeverityPill
                                                                color={personStatusHandler(item.dto.status?.nameRus || '') as SeverityPillColor}>
                                                                <Tooltip
                                                                    title={item.dto.status?.nameRus}>
                                                                    <Typography variant="caption" align={'left'} sx={{
                                                                        maxWidth: 100,
                                                                        textOverflow: 'ellipsis',
                                                                        overflow: 'hidden',
                                                                    }}>
                                                                        {item.dto.status?.nameRus}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </SeverityPill>
                                                        </Cell>
                                                        <Cell testid="jobs-tags">
                                                            <Box sx={{
                                                                maxWidth: 160,
                                                                display: '-webkit-box',
                                                                WebkitLineClamp: 2,
                                                                WebkitBoxOrient: 'vertical',
                                                                overflow: 'hidden'
                                                            }}>
                                                                {!!item.dto?.fromDoble &&
                                                                    <SeverityPill
                                                                        color={personStatusHandler('Взято из Дубля')}>
                                                                        <Tooltip
                                                                            title={'Взято из Дубля'}>
                                                                            <Typography variant="caption" align={'left'}
                                                                                        sx={{}}>
                                                                                {'Взято из Дубля'}
                                                                            </Typography>
                                                                        </Tooltip>
                                                                    </SeverityPill>
                                                                }
                                                                {!!item.dto.tags?.length && item.dto.tags?.map((tag) => (
                                                                    <SeverityPill
                                                                        color={personStatusHandler(tag?.nameRus || '')}>
                                                                        <Tooltip
                                                                            title={tag?.nameRus}>
                                                                            <Typography variant="caption" align={'left'}
                                                                                        sx={{
                                                                                            maxWidth: 100,
                                                                                            textOverflow: 'ellipsis',
                                                                                            overflow: 'hidden',
                                                                                        }}>
                                                                                {tag?.nameRus}
                                                                            </Typography>
                                                                        </Tooltip>
                                                                    </SeverityPill>
                                                                ))}
                                                            </Box>
                                                        </Cell>
                                                        <Cell testid="jobs-created">
                                                            {item.createDate}
                                                        </Cell>
                                                        <Cell testid="jobs-modified">
                                                            {item.date}
                                                        </Cell>
                                                        <Cell titleText={item.address} testid="jobs-address">
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body2">
                                                                {item.address}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell align={'center'} testid="jobs-primaryJob">
                                                            {!item.isTypeDepartment &&
                                                                <AuthorizePermission
                                                                    permissions={AllPermissions.person.personjobs.update}>
                                                                    <Checkbox
                                                                        checked={store.store.primaryJob.value === item.id}
                                                                        onChange={(e) => store.setPrimary(item.id)}
                                                                        disabled={(item.disabled && (store.store.primaryJob.value === item.id)) || store.isDisable}
                                                                    />
                                                                </AuthorizePermission>

                                                            }
                                                        </Cell>
                                                        {
                                                            !store.isDisable &&
                                                            <Cell align={'center'} testid="jobs-action">

                                                                <MenuComponent>
                                                                    <AuthorizePermission
                                                                        permissions={AllPermissions.person.personjobs.update}>
                                                                        <MenuItemComponent text={t('table.updateTick')}
                                                                                           icon={<CloudUploadIcon
                                                                                               fontSize="small"/>}
                                                                                           onClick={async () => await callTheProcessingFunc(item, item.updateTick.bind(item), async (e) => await item.store.store.loadPerson())()}
                                                                                           disabled={store.store.isDouble || store.store.isDead}/>
                                                                    </AuthorizePermission>
                                                                    {(store.items.length > 1 && store.store.primaryJob.value !== item.id) &&
                                                                        <AuthorizePermission
                                                                            permissions={AllPermissions.person.personjobs.delete}>
                                                                            <MenuItemComponent text={t('common.delete')}
                                                                                               icon={<DeleteIcon
                                                                                                   fontSize="small"/>}
                                                                                               onClick={() => {
                                                                                                   store.deleteDialogState = true;
                                                                                               }}
                                                                                               style={{color: '#D14343'}}
                                                                                               disabled={store.store.isDouble || store.store.isDead}/>
                                                                        </AuthorizePermission>
                                                                    }
                                                                </MenuComponent>
                                                            </Cell>
                                                        }

                                                    </TableRow>
                                                    {
                                                        open && (
                                                            <ExpandRow key={`${item.id}_`} item={item}>
                                                                <JobDetails job={item}/>
                                                            </ExpandRow>)
                                                    }
                                                </Fragment>)
                                        })
                                        : <Cell colSpan={11}>
                                            <EmptyBox/>
                                        </Cell>
                                }
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </Card>
                {store.items.length > 0 && <PaginationLocal store={store}/>}
                {!store.items.length && !!store.loading && <Box
                    sx={{
                        flexGrow: 1,
                        py: 3,
                    }}
                >
                    <Progress store={store}/>
                </Box>
                }
            </Page>
        </>
    );
});
