import {observer} from 'mobx-react';
import {useTranslation} from "react-i18next";
import {
    Link,
    Stack,
    Table,
    TableBody,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {PipeChart} from "../../../../components/charts/pipe/PipeChart";
import React, {Fragment} from "react";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {useManagementStore} from "../../../../stores";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {DefaultSelect} from "../../../../components/shared/components/DefaultSelect";
import {useNavigate} from "react-router-dom";
import {useSettings} from "../../../../core/hooks/use-settings";
import {Overview2} from "../../../../components/card-templates/overview2";
import {AnalyticsPage} from "../AnalyticsPage";
import {LineChart} from "../../../../components/charts/line/LineChart";
import Scrollbars from "react-custom-scrollbars";
import {Cell, EmptyBlock2, HCell} from "../../../../components";
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import {useClientPersonChartOptions} from "./chartHooks/useClientPersonChartOptions";
import {Overview3} from "../../../../components/card-templates/overview3";
import {useRequestByDateChartOptions} from "./chartHooks/useRequestByDateChartOptions";
import {Chart} from "../../../../components/charts/chart";
import EventRepeatRoundedIcon from '@mui/icons-material/EventRepeatRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import {ClientsIcon} from "../../../../components/icons/Clients";
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import {Overview4} from '../../../../components/card-templates/overview4';
import {DCRStatusEnum, RequestStatusEnum, RequestTypeEnum} from "../../../../stores/management/dcr/types";

export const DcrDashboards = observer(() => {
    const {t} = useTranslation();
    const {dashboardsPageStore} = useManagementStore();
    const settings = useSettings();
    const theme = useTheme();
    const navigate = useNavigate();

    const store = dashboardsPageStore.dcrDashboardsStore;

    const personClientChartOptions = useClientPersonChartOptions(store.personRequestsCountByClient);
    const organizationClientChartOptions = useClientPersonChartOptions(store.organizationRequestsCountByClient);
    const requestByDateChartOptions = useRequestByDateChartOptions(theme.palette.info.main, store.dateFilters.categoriesLabel);
    const speedCountByDateChartOptions = useRequestByDateChartOptions(theme.palette.primary.main, store.dateFilters.categoriesLabel);

    const overviewIcons = {
        "waitingVerification": <PendingIcon style={{fontSize: '1.8rem'}} color="success"/>,
        "onVerification": <ManageSearchRoundedIcon style={{fontSize: '1.8rem'}} color="info"/>,
        "verified": <CheckCircleRoundedIcon style={{fontSize: '1.8rem'}} color="secondary"/>,
        "decline": <CancelIcon style={{fontSize: '1.8rem'}} color="error"/>,
        "double": <ContentCopyIcon style={{fontSize: '1.8rem'}} color="error"/>,
        "error": <ErrorRoundedIcon style={{fontSize: '1.8rem'}} color="error"/>,
    }

    const handleReportNavigate = (prop: {
        status?: RequestStatusEnum[],
        entity?: RequestTypeEnum[],
        type?: DCRStatusEnum[],
        client?: string,
        isError?: boolean,
        responsibility?: string
    }) => {
        store.saveReportFilters(prop)
        navigate('/dcr')
    }

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         store.updateState()
    //     }, 60000);
    //     return () => clearInterval(timer);
    // }, []);

    return (
        <AnalyticsPage store={store.dateFilters} title={t("pageNames.dcr")}>
            <>
                <Grid xs={12} md={4}>
                    <Stack spacing={3} direction="column" sx={{height: '100%'}}>
                        <Overview2 title={t("analytics.dcr.main.clientsCount")}
                                   cardSx={{padding: '21px 24px'}}
                                   sx={{flexBasis: '50%'}}
                                   icon={<ClientsIcon/>}
                                   description={t("analytics.dcr.main.clientsCountDescription").replace("{count}", (store.clientsCount?.infoCount ?? 0).toString())}
                                   count={store.clientsCount?.count ?? 0}
                        ></Overview2>
                        <Overview2 title={t("analytics.dcr.main.withMoreRequests")}
                                   icon={<AutoAwesomeMotionIcon sx={{
                                       fontSize: '4.1rem',
                                       color: theme.palette.primary.main,
                                       padding: '0 10px'
                                   }}/>}
                                   cardSx={{padding: '21px 24px'}}
                                   sx={{flexBasis: '50%'}}
                                   description={`${store.countWithMoreRequestsByPerson.length} ${t("common.persons")}, ${store.countWithMoreRequestsByOrganization.length} ${t("common.organizations")}`}
                                   count={store.countWithMoreRequestsByPerson.length + store.countWithMoreRequestsByOrganization.length}
                        ></Overview2>
                    </Stack>
                </Grid>
                <Grid xs={12} md={4}>
                    <Overview3 title={
                        <Link onClick={(e) => handleReportNavigate({})}
                              style={{cursor: 'pointer', fontWeight: 600}}>
                            {t("analytics.dcr.main.requestsCount")}
                        </Link>
                    }
                               sx={{height: '100%'}}
                               description={store.requestsCount?.count ?? 0}
                               subTitle={t("analytics.dcr.main.requestsCountDescription")}
                               subDescription={store.requestsCount?.infoCount ?? 0}
                               icon={<EventRepeatRoundedIcon style={{fontSize: '2.8rem'}} color="success"/>}>
                        <Chart
                            height={100}
                            options={requestByDateChartOptions}
                            series={[{data: store.requestByDateData}]}
                            type="area"
                        />
                    </Overview3>
                </Grid>
                <Grid xs={12} md={4}>
                    <Overview3 title={
                        <Link
                            onClick={(e) => handleReportNavigate({
                                status: [
                                    RequestStatusEnum.Decline,
                                    RequestStatusEnum.Partialy,
                                    RequestStatusEnum.Verified]
                            })}
                            style={{cursor: 'pointer', fontWeight: 600}}>
                            {t("analytics.dcr.main.speedCount")}
                        </Link>
                    }
                               description={store.speedCount?.count ?? 0}
                               subTitle={t("analytics.dcr.main.speedCountDescription")}
                               subDescription={store.speedCount?.infoCount ?? 0}
                               icon={<PeopleRoundedIcon style={{fontSize: '2.8rem'}} color="info"/>}>
                        <Chart
                            height={100}
                            options={speedCountByDateChartOptions}
                            series={[{data: store.speedCountByDateData}]}
                            type="area"
                        />
                    </Overview3>
                </Grid>
                {store.statusesCard.map((x, i) =>
                    <Grid xs={12} md={2}>
                        <Overview4 key={i} title={
                            <Link
                                onClick={(e) => handleReportNavigate(x.name === "Error" ? {isError: true} : {status: x.values})}
                                style={{cursor: 'pointer', fontWeight: 600}}>
                                {t("analytics.dcr.statuses." + x.name)}
                            </Link>
                        }
                                   cardSx={{padding: '24px 24px!important'}}
                                   icon={(overviewIcons as any)[x.name]}
                                   count={x.count}
                        ></Overview4>
                    </Grid>
                )}
                <Grid xs={12} md={6}>
                    <Card sx={{height: '100%'}}>
                        <CardHeader title={
                            <Link onClick={(e) => handleReportNavigate({entity: [RequestTypeEnum.Person]})}
                                  style={{cursor: 'pointer', fontWeight: 600}}>
                                {t("analytics.dcr.pipeCharts.persons")}
                            </Link>
                        }
                                    action={(
                                        <Tooltip
                                            title={t("analytics.dcr.pipeCharts.personsDescription")}>
                                            <InfoOutlinedIcon/>
                                        </Tooltip>
                                    )}
                                    style={{paddingBottom: 0}}/>
                        <CardContent>
                            <PipeChart apexOptions={personClientChartOptions}/>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} md={6}>
                    <Card sx={{height: '100%'}}>
                        <CardHeader title={
                            <Link onClick={(e) => handleReportNavigate({entity: [RequestTypeEnum.Organization]})}
                                  style={{cursor: 'pointer', fontWeight: 600}}>
                                {t("analytics.dcr.pipeCharts.organizations")}
                            </Link>
                        }
                                    action={(
                                        <Tooltip
                                            title={t("analytics.dcr.pipeCharts.organizationsDescription")}>
                                            <InfoOutlinedIcon/>
                                        </Tooltip>
                                    )}
                                    style={{paddingBottom: 0}}/>
                        <CardContent>
                            <PipeChart apexOptions={organizationClientChartOptions}/>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} md={12}>
                    <DefaultSelect store={store.client}
                                   title={t('dcrPage.requestTable.client')}
                                   nameExp="name"
                                   valueExp="id"
                                   style={{width: '32%'}}
                    />
                </Grid>
                <Grid xs={12} md={12}>
                    <Card>
                        <CardHeader
                            style={{paddingBottom: 0}}
                            title={
                                store?.client?.value ?
                                    <Link onClick={(e) => handleReportNavigate({client: store.client.value!.name!})}
                                          style={{cursor: 'pointer', fontWeight: 600}}>
                                        {t("analytics.dcr.lineChart.title")}
                                    </Link> : t("analytics.dcr.lineChart.title")
                            }
                            action={
                                <Tooltip title={t("analytics.dcr.lineChart.action")}>
                                    <InfoOutlinedIcon/>
                                </Tooltip>
                            }
                        />
                        <CardContent>
                            <LineChart data={store.requestsByClientRangeData}
                                       periods={store.dateFilters.categoriesLabel}/>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} md={12}>
                    <Card>
                        <CardHeader
                            style={{paddingBottom: 0}}
                            action={(
                                <Tooltip title={t("analytics.dcr.responsibilityTable.action")}>
                                    <InfoOutlinedIcon/>
                                </Tooltip>
                            )}
                            title={t("analytics.dcr.responsibilityTable.header")}
                        />
                        <CardContent>
                            <Scrollbars style={{height: 400,}} autoHide>
                                <Table style={{borderCollapse: 'separate'}}>
                                    <TableHead style={{position: "sticky", top: 0}}>
                                        <TableRow>
                                            <HCell
                                                style={{padding: '10px 30px'}}>{t('analytics.dcr.responsibilityTable.columns.name')}</HCell>
                                            <HCell styleText={{
                                                marginLeft: 'auto',
                                                paddingRight: 16
                                            }}>{t('analytics.dcr.statuses.onVerification')}
                                            </HCell>
                                            <HCell styleText={{
                                                marginLeft: 'auto',
                                                paddingRight: 16
                                            }}>{t('analytics.dcr.statuses.verified')}
                                            </HCell>
                                            <HCell styleText={{
                                                marginLeft: 'auto',
                                                paddingRight: 16
                                            }}>{t('analytics.dcr.statuses.decline')}
                                            </HCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            store.responsibilityTable.map((i, u) => {
                                                return <Fragment key={u}>
                                                    <TableRow
                                                        hover
                                                        key={u}
                                                    >
                                                        <Cell style={{padding: '15px 30px'}}>
                                                            <Link
                                                                onClick={(e) => handleReportNavigate({
                                                                    responsibility: i.id
                                                                })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    fontWeight: 600
                                                                }}>
                                                                {i.name}
                                                            </Link>
                                                        </Cell>
                                                        <Cell align='right'>
                                                            <Link
                                                                onClick={(e) => handleReportNavigate({
                                                                    responsibility: i.id,
                                                                    status: [RequestStatusEnum.OnVerification]
                                                                })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    fontWeight: 600
                                                                }}>
                                                                {i.onVerification}
                                                            </Link>
                                                        </Cell>
                                                        <Cell align='right'>
                                                            <Link
                                                                onClick={(e) => handleReportNavigate({
                                                                    responsibility: i.id,
                                                                    status: [RequestStatusEnum.Verified, RequestStatusEnum.Partialy]
                                                                })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    fontWeight: 600
                                                                }}>
                                                                {i.verified}
                                                            </Link>
                                                        </Cell>
                                                        <Cell align='right'>
                                                            <Link
                                                                onClick={(e) => handleReportNavigate({
                                                                    responsibility: i.id,
                                                                    status: [
                                                                        RequestStatusEnum.Decline
                                                                    ]
                                                                })}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    fontWeight: 600
                                                                }}>
                                                                {i.decline}
                                                            </Link>
                                                        </Cell>
                                                    </TableRow>
                                                </Fragment>
                                            })
                                        }
                                        {
                                            store.responsibilityTable.length == 0 &&
                                            <EmptyBlock2/>
                                        }
                                    </TableBody>
                                    {
                                        store.responsibilityTable.length > 0 &&
                                        <TableFooter
                                            style={{
                                                position: 'sticky',
                                                bottom: -1,
                                                backgroundColor: settings.paletteMode == 'dark' ? '#1F2937' : '#F8F9FA'
                                            }}>
                                            <Fragment key='summaryAggregationData-total'>
                                                <TableRow
                                                    hover
                                                    key='summaryAggregationData-total'
                                                >
                                                    <Cell style={{padding: '15px 30px'}}>
                                                        <Typography variant="subtitle2">
                                                            {t("common.allCount")}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell align='right'>
                                                        <Typography variant="subtitle2">
                                                            {store.responsibilityTable.length > 0 ?
                                                                (store.responsibilityTable.map(x => x.onVerification ?? 0).reduce((x, y) => x + y)) : 0}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell align='right'>
                                                        <Typography variant="subtitle2">
                                                            {store.responsibilityTable.length > 0 ?
                                                                (store.responsibilityTable.map(x => x.verified ?? 0).reduce((x, y) => x + y)) : 0}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell align='right'>
                                                        <Typography variant="subtitle2">
                                                            {store.responsibilityTable.length > 0 ?
                                                                (store.responsibilityTable.map(x => x.decline ?? 0).reduce((x, y) => x + y)) : 0}
                                                        </Typography>
                                                    </Cell>
                                                </TableRow>
                                            </Fragment>
                                        </TableFooter>
                                    }
                                </Table>
                            </Scrollbars>
                        </CardContent>
                    </Card>
                </Grid>

            </>
        </AnalyticsPage>
    );
});
