/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * 
 * @export
 * @interface CreatePersonDto
 */
export interface CreatePersonDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    firstNameId: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    middleNameId?: string | null;
    /**
     *
     * @type {string}
     * @memberof CreatePersonDto
     */
    maidenNameId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    surnameId: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    genderId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePersonDto
     */
    hasNoMiddleName?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePersonDto
     */
    specialityIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    primarySpecialityId: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    jobOrganizationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    depJobOrganizationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonDto
     */
    jobPostId: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePersonDto
     */
    jobStatusId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePersonDto
     */
    tags?: Array<string> | null;
}

export function CreatePersonDtoFromJSON(json: any): CreatePersonDto {
    return CreatePersonDtoFromJSONTyped(json, false);
}

export function CreatePersonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePersonDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstNameId': json['firstNameId'],
        'middleNameId': !exists(json, 'middleNameId') ? undefined : json['middleNameId'],
        'maidenNameId': !exists(json, 'maidenNameId') ? undefined : json['maidenNameId'],
        'surnameId': json['surnameId'],
        'genderId': json['genderId'],
        'hasNoMiddleName': !exists(json, 'hasNoMiddleName') ? undefined : json['hasNoMiddleName'],
        'specialityIds': !exists(json, 'specialityIds') ? undefined : json['specialityIds'],
        'primarySpecialityId': json['primarySpecialityId'],
        'jobOrganizationId': !exists(json, 'jobOrganizationId') ? undefined : json['jobOrganizationId'],
        'depJobOrganizationId': !exists(json, 'depJobOrganizationId') ? undefined : json['depJobOrganizationId'],
        'jobPostId': json['jobPostId'],
        'jobStatusId': json['jobStatusId'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function CreatePersonDtoToJSON(value?: CreatePersonDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstNameId': value.firstNameId,
        'middleNameId': value.middleNameId,
        'maidenNameId': value.maidenNameId,
        'surnameId': value.surnameId,
        'genderId': value.genderId,
        'hasNoMiddleName': value.hasNoMiddleName,
        'specialityIds': value.specialityIds,
        'primarySpecialityId': value.primarySpecialityId,
        'jobOrganizationId': value.jobOrganizationId,
        'depJobOrganizationId': value.depJobOrganizationId,
        'jobPostId': value.jobPostId,
        'jobStatusId': value.jobStatusId,
        'tags': value.tags,
    };
}

