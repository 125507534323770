import {getApiConfiguration} from "../../../../../../core";
import {
    JobsApi,
    JobStatusesApi,
    OrganizationDto,
    OrganizationsApi,
    PersonJobDto,
    PersonStatusDto,
    PostDto,
    PostsApi,
} from "../../../../../../services/management";
import {createElement} from "react";
import {ChangeRequestField, FirstValueDto} from "../../../../../../services/dcr";
import {CustomFieldStore} from "./CustomFieldStore";
import {PersonStore} from "../../specifications/entity/PersonStore";
import {JobComponent} from "../../../../../../pages/dcr/requests/fieldsComponents/JobComponent";
import {DefaultSelectStore} from "../../../../../../components/shared/DefaultSelectStore";
import {JobStatusesNew} from "../../../../../../core/const";
import {t} from "i18next";
import {DCRStatusEnum, RequestField, RequestFieldTypeEnum} from "../../../types";
import {DefaultValueStore} from "../../../../../../components/shared/DefaultValueStore";
import {getById} from "../../../services";
import {getOrgName} from "../../../../../utils";
import {SeverityPillColor} from "../../../../../../components/severity-pill";
import {computed, makeObservable, observable} from "mobx";

export class JobStore extends CustomFieldStore<PersonStore> {
    requestPosition?: PostDto;
    requestOrganization?: OrganizationDto;

    Component: React.FunctionComponentElement<any>;

    selectedJob: DefaultSelectStore<PersonJobDto> = new DefaultSelectStore<PersonJobDto>(null,
        () => new JobsApi(getApiConfiguration()).getAllPersonJobs({
            personId: this.store.data.id!,
            statusIds: [JobStatusesNew.Work]
        }));

    jobStatus: DefaultSelectStore<PersonStatusDto> = new DefaultSelectStore<PersonStatusDto>(null,
        (filters) => new JobStatusesApi(getApiConfiguration()).getJobStatuses({...filters}))

    organization: DefaultSelectStore<OrganizationDto> = new DefaultSelectStore<OrganizationDto>(null,
        (filters) => new OrganizationsApi(getApiConfiguration()).getOrganizations({...filters}))

    position: DefaultSelectStore<PostDto> = new DefaultSelectStore<PostDto>(null,
        (filters) => new PostsApi(getApiConfiguration()).getPosts({
            ...filters,
            orderBy: 'name_rus',
            order: 'ascending',
            showOnlyActive: true
        }))

    isPrimary: DefaultValueStore<boolean> = new DefaultValueStore<boolean>(false);

    primaryJob: DefaultSelectStore<PersonJobDto> = new DefaultSelectStore<PersonJobDto>(null,
        () => new JobsApi(getApiConfiguration()).getAllPersonJobs({
            personId: this.store.data.id!,
            statusIds: [JobStatusesNew.Work]
        }));

    constructor(store: PersonStore) {
        super(store);
        this.selectedJob.required = true;
        this.jobStatus.required = true;
        this.organization.required = true;
        this.position.required = true;
        this.primaryJob.required = true;

        this.Component = createElement(JobComponent, {store: this}, null)

        this.organization.nameExp = (dto) => getOrgName(dto);
        this.organization.isSearchById = true;

        this.isPrimary.getReadValue = (e) => t(!!e ? "common.yes" : "common.no")
        this.isPrimary.type = "checkbox"

        this.jobStatus.defaultSearch = true;

        this.selectedJob.onChanged.push((e) => {
            this.position.value = e?.post!
            this.organization.value = e?.organization!;
            if (this.store._store._store.isCompletedStatus)
                this.jobStatus.value = e?.status!
            this.isPrimary.value = this.store.primaryJob?.id == e?.id
        })

        this.primaryJob.nameExp = (x) => x.organization.names.filter((x: any) => x.typeName.nameRus == "Синдикативное")[0].name + " : " + x.post.nameRus
        this.primaryJob.filterOption = (value) => value.id != this.store.jobInfo?.id

        this.selectedJob.nameExp = (x) => x.organization.names.filter((x: any) => x.typeName.nameRus == "Синдикативное")[0].name + " : " + x.post.nameRus

        this.jobStatus.onChanged.push((e) => {
            if (e?.id == JobStatusesNew.NotWork || e?.id == JobStatusesNew.Dekret) {
                this.isPrimary.value = false;
            }
            else {
                this.isPrimary.value = this.selectedJob.valueId && this.selectedJob.valueId === this.store.primaryJob?.id;
            }
            this.isPrimary.disabled = e?.id == JobStatusesNew.NotWork || e?.id == JobStatusesNew.Dekret
        })

        this.validate()

        makeObservable(this, {
            linkOrganization: computed,
            orgFirstField: computed,
            positionFirstField: computed,
            orgField: computed,
            positionField: computed,
            label: computed,
            visibilitySelectedJob: computed,
            visibilityNewJob: computed,
            visibilityPrimaryJob: computed,
            edit: computed,
            fields: computed,
            orgId: computed,
            postId: computed,
            selectedAllReadyJob: computed,
            requestPosition: observable,
            requestOrganization: observable,
            statusName: computed
        })
    }

    get statusName(): string {
        const nameArchivereason = this.store._store.dto?.additionalFields['nameArchivereason'];
        const archivereason = this.store._store.firstRequestFields.find(x => x.field == "archivereason");
        return nameArchivereason ?? (archivereason ? archivereason.newName ?? (archivereason.value == "1" ? "Работает" : "Не работает") : t("common.empty"));
    }

    get requestPositionName(): string {
        return this.store._store?.firstRequestFields?.find(x => x.field == "position")?.newName ??
            this.store._store?.dto?.additionalFields['position'] ?? this.position?.value?.nameRus;
    }


    get linkOrganization(): string {
        return this.orgId ? `/organizations/${this.orgId}` : ''
    }

    get orgFirstField(): FirstValueDto | undefined {
        return this.store._store.firstRequestFields.find(x => x.field == 'company' || x.field == 'companyId');
    }

    get positionFirstField(): FirstValueDto | undefined {
        return this.store._store.firstRequestFields.find(x => x.field == 'position');
    }

    get orgField(): FirstValueDto | undefined {
        return this.store._store.requestFields.find(x => x.field == 'company' || x.field == 'companyId');
    }

    get positionField(): FirstValueDto | undefined {
        return this.store._store.requestFields.find(x => x.field == 'position');
    }

    get statusField(): FirstValueDto | undefined {
        return this.store._store.requestFields.find(x => x.field == 'archivereason');
    }

    get isPrimaryField(): FirstValueDto | undefined {
        return this.store._store.requestFields.find(x => x.field == 'isPrimary');
    }

    get label(): { title: string, color: SeverityPillColor } {

        const isNew = this.visibilityNewJob;

        const selectedPrimary = !!this.selectedAllReadyJob && this.selectedAllReadyJob?.id == this.store.primaryJob?.id;
        const selectedAdditional = !!this.selectedAllReadyJob && this.selectedAllReadyJob?.id != this.store.primaryJob?.id;

        const isPrimary = this.selectedJob?.value?.id == this.store.primaryJob?.id

        return {
            color: isNew ? (selectedPrimary ? "info" : selectedAdditional ? "warning" : "success") : isPrimary ? "info" : "warning",
            title: isNew ? (selectedPrimary ? "primary" : selectedAdditional ? "additional" : "new") : isPrimary ? "primary" : "additional",
        }
    }

    get selectedAllReadyJob() {
        return this.store.jobs.filter(x => x.post.id == this.postId && x.organization.id == this.orgId)?.[0]
    }

    initStatus() {
        if (this.statusField?.value)
            this.jobStatus.pull().then(x => {
                this.jobStatus.value = this.jobStatus.items.find(f => f.id == parseInt(this.statusField!.value!)) ?? null
            })
    }

    get visibilitySelectedJob(): boolean {
        return !!this.selectedJob.value;
    };

    get visibilityNewJob(): boolean {
        return !this.visibilitySelectedJob;
    };

    get visibilityIsPrimary(): boolean {
        return !this.visibilitySelectedJob && this.store.dto.dcrStatus !== DCRStatusEnum.Create;
    };

    get visibilityPrimaryJob(): boolean {
        const isUpdateRequest = this.store.dto.dcrStatus === DCRStatusEnum.Update;
        const isNotWorkJobStatus = this.jobStatus.value?.id == JobStatusesNew.NotWork || this.jobStatus.value?.id == JobStatusesNew.Dekret;
        const anyIsWorkJob = this.store.jobsCount > 0;

        const selectedPrimaryJob =
            this.store.jobInfo?.organizationId == this.selectedJob.value?.organization.id &&
            this.store.jobInfo?.positionId == this.selectedJob.value?.post.id

        return isUpdateRequest && (isNotWorkJobStatus || !this.isPrimary.value) && anyIsWorkJob && selectedPrimaryJob
    };


    get edit(): boolean {
        return this.store._store.edit;
    }

    get fields(): RequestField[] {
        return this.store._store.fields;
    }

    async pull(): Promise<void> {
        if (this.orgFirstField) {
            this.requestOrganization = await getById({
                id: this.orgFirstField.value,
                external: this.orgFirstField.type === RequestFieldTypeEnum.External,
                getInternal: (x) => new OrganizationsApi(getApiConfiguration()).getOrganizationById({id: x as string}),
                getExternal: (x) => new OrganizationsApi().getOrganizations({searchByExternalId: x}),
            }) ?? undefined;
            this.organization.value = this.requestOrganization ?? null;
        }
        if (this.positionFirstField) {
            this.requestPosition = await getById({
                id: this.positionFirstField.value,
                external: this.positionFirstField.type === RequestFieldTypeEnum.External,
                getInternal: (x) => new PostsApi().getPostById({id: x as string}),
                getExternal: (x) => new PostsApi().getPosts({searchByExternalId: x}),
            }) ?? undefined;
        }

        if (this.store._store._store.isCompletedStatus) {
            this.selectedJob.value = this.store.jobs.find(x => x.organization.id == this.orgField?.value && x.post.id == this.positionField?.value) ?? null;
        } else {
            if (!this.requestOrganization) {
                this.selectedJob.value = this.store.primaryJob ?? null;
            } else {
                const jobs = this.store.jobs.filter(j => j.organization.id == this.requestOrganization?.id);
                if (jobs.length > 0) {
                    this.selectedJob.value = jobs.find(x => (this.requestPosition && x.post.id == this.requestPosition?.id) || x.id == this.store.primaryJob?.id || true) ?? null;
                }
            }
            this.jobStatus.value = null;
        }
        if (this.isPrimaryField)
            this.isPrimary.value = this.isPrimaryField.value == "1" || this.isPrimaryField.value?.toLowerCase() == "true"
    }

    reset(): void {
        this.selectedJob.reset()
        this.jobStatus.reset()
        this.organization.reset()
        this.position.reset()
        this.isPrimary.reset()
        this.primaryJob.reset()
    }

    validate(): void {
        this.organization.validate()
        this.selectedJob.validate()
        this.jobStatus.validate()
        this.position.validate()
        this.isPrimary.validate()
        this.primaryJob.validate()
    }

    get valid(): boolean {
        return this.position.valid && this.jobStatus.valid &&
            (!this.visibilitySelectedJob || this.selectedJob.valid) &&
            (!this.visibilityNewJob || (this.organization.valid && this.isPrimary.valid)) &&
            (!this.visibilityPrimaryJob || this.primaryJob.valid)
    }

    get orgId(): string | undefined {
        return this.visibilitySelectedJob ? this.selectedJob.value?.organization.id : this.organization.value?.id
    }

    get postId(): string | undefined {
        return this.position.value?.id
    }

    get value(): { additionalFields: { [p: string]: string | null | undefined } | null | undefined; fields: ChangeRequestField[] | null | undefined } {

        const additionalFields: any = {};
        const fields: ChangeRequestField[] = [];

        fields.push({
            field: 'position',
            value: this.postId,
            type: RequestFieldTypeEnum.Data360
        })
        fields.push({
            field: 'archivereason',
            value: String(this.jobStatus.valueId),
            type: RequestFieldTypeEnum.Data360
        })
        fields.push({
            field: 'company',
            value: this.orgId,
            type: RequestFieldTypeEnum.Data360
        })

        additionalFields['isPrimary'] = this.isPrimary.value ? '1' : '0';

        if (this.visibilitySelectedJob){
            additionalFields['jobId'] = this.selectedJob.valueId;
        }
        if (this.selectedAllReadyJob) {
            additionalFields['jobId'] = this.selectedAllReadyJob.id;
        }

        if (this.visibilityPrimaryJob) {
            additionalFields['primaryJob'] = this.primaryJob.valueId;
        }

        return {additionalFields, fields}
    }
}

