import {observer} from 'mobx-react';
import {Button, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {JobCreatorStore} from "../../../../stores/management/persons/tabs/jobs/JobCreatorStore";
import {FormRoot, Search} from "../../../../components";
import {LocalitySelectWithTypes} from '../../../../components/select/Locality/LocalitySelectWithTypes';
import {DefaultSelect} from "../../../../components/shared/components/DefaultSelect";

interface Props {
    store: JobCreatorStore;
}

export const JobCreator = observer(({store}: Props) => {
    const {t} = useTranslation();
    const [localityName, setLocalityName] = useState('Поиск по всем регионам')

    const localityNameHandler = () => {
        const locality = store.localitiesFilter.locality.currentLocality?.value;
        const house = store.localitiesFilter?.house?.value;
        if (!locality) return 'Поиск по всем регионам'
        const lName = `Поиск по ${locality.nameRus} ${locality.typeLocality.nameRus} ${house}`
        return lName
    }

    return (
        <FormRoot>
            <Search
                value={store.searchById.value}
                onChange={async t => store.searchById.value = t}
                placeholder='Поиск по Id'
                style={{width: '100%'}}
            />
            <Typography variant="overline" display="block">Выбор региона для поиска организации</Typography>

            <LocalitySelectWithTypes locality={store.localitiesFilter.locality.locality} store={store.localitiesFilter}
                                     style={{marginRight: 20, backgroundColor: 'red'}}/>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingRight: 10,
                paddingLeft: 10,
                marginBottom: 30,
                marginTop: 15
            }}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    size={'medium'}
                    onClick={() => {
                        store.localitiesFilter.save();
                        store.localitiesFilter.resetItems();
                        setLocalityName(localityNameHandler)
                    }}
                    style={{flex: 1, marginRight: 20}}
                    disabled={!store.localitiesFilter.locality.locality.locality}
                >
                    {t('create.addLocality')}
                </Button>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    size={'medium'}
                    onClick={() => {
                        store.localitiesFilter.locality.reset();
                        setLocalityName(localityNameHandler)
                    }}
                >
                    {t('buttons.reset')}
                </Button>
            </div>

            <DefaultSelect store={store.personOrganization}
                           autoFocus
                           testid="createJobForm-personOrganization"
                           title={!store.isDepOrg ? `${t('common.organization')} - ${localityName}` : `${t('common.organization')} (${t('common.jobDep')}) - ${localityName}`}
                           onValue={() => {
                               store.onParentJob()
                           }}
                           nameExp={store.personOrganization.nameExp}
                           hasLabel/>
            {(store.isDepOrg && !!store.hasJobParent().length) &&
                <DefaultSelect store={store.parentJob}
                               testid="createJobForm-parentJob"
                               onValue={() => store.onPersonPost()}
                               nameExp={store.parentJob.nameExp}
                               title={t('common.parentJob')}
                               hasLabel/>
            }
            {(store.isDepOrg && !store.parentJob?.value && !!store.hasJobParent().length) &&
                <Typography sx={{fontSize: 12, marginTop: -2}} color='secondary'>
                    {t('errors.depJob')}
                </Typography>
            }
            <DefaultSelect store={store.personPost}
                           testid="createJobForm-personPost"
                           title={t('common.position')}
                           hasLabel/>
        </FormRoot>
    );
});
