import {observer} from 'mobx-react';
import {Button, Typography} from '@mui/material';
import {JobCreatorStore} from "../../../stores/management/persons/tabs/jobs/JobCreatorStore";
import {CreateButtonsGroup, FormRoot, FormRow, Search} from '../../../components';
import {useTranslation} from "react-i18next";
import {DefaultSelect} from '../../../components/shared/components/DefaultSelect';
import {LocalitySelectWithTypes} from "../../../components/select/Locality/LocalitySelectWithTypes";
import React, {useState} from "react";

interface Props {
    store: JobCreatorStore;
}

export const JobCreator = observer(({store}: Props) => {
    const {t} = useTranslation();
    const [localityName, setLocalityName] = useState('Поиск по всем регионам')

    const localityNameHandler = () => {
        const locality = store.localitiesFilter.locality.currentLocality?.value;
        const house = store.localitiesFilter?.house?.value;
        if (!locality) return 'Поиск по всем регионам'
        const lName = `Поиск по ${locality.nameRus} ${locality.typeLocality.nameRus} ${house}`
        return lName
    }

    return (
        <FormRoot>
            <FormRow>
                <Search
                    autoFocus
                    value={store.searchById.value}
                    onChange={async t => store.searchById.value = t}
                    placeholder='Поиск по Id'
                    style={{width: '100%'}}
                />
            </FormRow>
            <Typography variant="overline" display="block">Выбор региона для поиска организации</Typography>

            <LocalitySelectWithTypes locality={store.localitiesFilter.locality.locality} store={store.localitiesFilter}
                style={{ marginRight: 20, backgroundColor: 'red' }} />

            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingRight: 10,
                paddingLeft: 10,
                marginBottom: 30,
                marginTop: 15
            }}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    size={'medium'}
                    onClick={() => {
                        store.localitiesFilter.save();
                        store.localitiesFilter.resetItems();
                        setLocalityName(localityNameHandler)
                    }}
                    style={{ flex: 1, marginRight: 20 }}
                    disabled={!store.localitiesFilter.locality.locality.locality}
                >
                    {t('create.addLocality')}
                </Button>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    size={'medium'}
                    onClick={() => {
                        store.localitiesFilter.locality.reset();
                        setLocalityName(localityNameHandler)
                    }}
                >
                    {t('buttons.reset')}
                </Button>
            </div>

            <FormRow>
                <DefaultSelect store={store.personOrganization}
                               testid="createJobForm-personOrganization"
                               title={!store.isDepOrg ? `${t('common.organization')} - ${localityName}` : `${t('common.organization')} (${t('common.jobDep')}) - ${localityName}`}
                               onValue={() => {
                                   store.onParentJob()
                               }}
                               nameExp={store.personOrganization.nameExp}
                               hasLabel />
            </FormRow>
            {(store.isDepOrg && !!store.hasJobParent().length) &&
                <FormRow>
                    <DefaultSelect store={store.parentJob}
                                   testid="createJobForm-parentJob"
                                   onValue={() => store.onPersonPost()}
                                   nameExp={store.parentJob.nameExp}
                                   title={t('common.parentJob')}
                                   hasLabel/>
                </FormRow>
            }
            {(store.isDepOrg && !store.parentJob?.value && !!store.hasJobParent().length) &&
                <Typography sx={{fontSize: 12, marginTop: -2}} color='secondary'>
                    {t('errors.depJob')}
                </Typography>
            }
            <FormRow>
                <DefaultSelect store={store.personPost}
                    testid="createJobForm-personPost"
                    title={t('common.position')}
                    hasLabel />
            </FormRow>
            <FormRow>
                <DefaultSelect store={store.personStatus}
                    testid="createJobForm-personStatus"
                    title={t('common.status')}
                    hasLabel />
            </FormRow>
            <CreateButtonsGroup
                testid="сreateJobForm"
                onCancel={() => {
                    store.localitiesFilter.locality.reset();
                    store.Open = false
                }}
                onSave={async () => await store.create()}
                isCancelBtnDisabled={store.processing}
                isSaveBtnDisabled={store.processing || !store.valid} />
        </FormRoot>
    );
});
