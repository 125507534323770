import {
    Box,
    Breadcrumbs,
    Card,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import moment from 'moment/moment';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AuthorizePermission,
    Cell,
    EmptyBox,
    HCell,
    Pagination,
    Progress,
} from '../../../../components';
import { CopyToClipboard } from '../../../../components/CopyToClipboard';
import { NavLink } from '../../../../components/NavLink';
import { FiltersComponent } from '../../../../components/filters/FiltersComponent';
import { SeverityPill } from '../../../../components/severity-pill';
import { DefaultDate } from '../../../../components/shared/DefaultDate';
import { DefaultMultiSelect } from '../../../../components/shared/components/DefaultMultiSelect';
import { useManagementStore } from '../../../../stores';

export const ExchangeReport = observer(() => {
    const { t } = useTranslation();
    const { dashboardsPageStore } = useManagementStore();

    const store = dashboardsPageStore.exchangeReportStore;

    return (
        <AuthorizePermission permissions="" showWarning role={'Admin'}>
            <Breadcrumbs separator="/">
                <Typography color="primary" variant="h6">
                    {t('analytics.reports')}
                </Typography>
                <Typography color="textSecondary" variant="h6">
                    {t('pageNames.exchange')}
                </Typography>
            </Breadcrumbs>
            <Box>
                <FiltersComponent>
                    <Box
                        sx={{ mt: 3 }}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 0,
                        }}
                    >
                        <DefaultDate
                            store={store.startDate}
                            style={{ width: '33%', marginTop: 16 }}
                            title={t(
                                'analytics.exchange.report.filters.startDate'
                            )}
                        />
                        <DefaultDate
                            store={store.endDate}
                            style={{ width: '33%', marginTop: 16 }}
                            title={t(
                                'analytics.exchange.report.filters.endDate'
                            )}
                        />
                        <DefaultMultiSelect
                            store={store.client}
                            title={t(
                                'analytics.exchange.report.filters.clients'
                            )}
                            nameExp={store.client.nameExp}
                            valueExp="boundId"
                            style={{ width: '33%' }}
                        />
                    </Box>
                    <Box
                        sx={{ mb: 1 }}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: 10,
                            marginTop: -8,
                        }}
                    >
                        <DefaultMultiSelect
                            store={store.entityType}
                            title={t(
                                'analytics.exchange.report.filters.entity'
                            )}
                            nameExp={store.entityType.nameExp}
                            valueExp="id"
                            style={{ width: '24%' }}
                        />
                        <DefaultMultiSelect
                            store={store.status}
                            title={t(
                                'analytics.exchange.report.filters.status'
                            )}
                            nameExp={store.status.nameExp}
                            valueExp="id"
                            style={{ width: '24%' }}
                        />
                        <DefaultMultiSelect
                            store={store.speciality}
                            title={t(
                                'analytics.exchange.report.filters.speciality'
                            )}
                            nameExp={store.speciality.nameExp}
                            valueExp="id"
                            style={{ width: '24%' }}
                        />
                        <DefaultMultiSelect
                            store={store.orgType}
                            title={t(
                                'analytics.exchange.report.filters.orgType'
                            )}
                            nameExp={store.orgType.nameExp}
                            valueExp="id"
                            style={{ width: '24%' }}
                        />
                    </Box>
                </FiltersComponent>

                <Card sx={{ mb: 8, overflow: 'unset' }}>
                    <Table sx={{ minWidth: 1250 }}>
                        <TableHead
                            style={{ position: 'sticky', top: 63, zIndex: 999 }}
                        >
                            <TableRow>
                                <HCell
                                    style={{ padding: '6px 30px' }}
                                    store={store}
                                >
                                    {t('analytics.exchange.report.columns.id')}
                                </HCell>
                                <HCell store={store}>
                                    {t(
                                        'analytics.exchange.report.columns.client'
                                    )}
                                </HCell>
                                <HCell style={{ width: 120 }} store={store}>
                                    {t(
                                        'analytics.exchange.report.columns.entity'
                                    )}
                                </HCell>
                                <HCell store={store}>
                                    {t(
                                        'analytics.exchange.report.columns.entityId'
                                    )}
                                </HCell>
                                <HCell store={store}>
                                    {t(
                                        'analytics.exchange.report.columns.actionType'
                                    )}
                                </HCell>
                                <HCell store={store}>
                                    {t(
                                        'analytics.exchange.report.columns.completedAt'
                                    )}
                                </HCell>
                                <HCell store={store}>
                                    {t(
                                        'analytics.exchange.report.columns.status'
                                    )}
                                </HCell>
                                <HCell store={store}>
                                    {t(
                                        'analytics.exchange.report.columns.speciality'
                                    )}
                                </HCell>
                                <HCell store={store} style={{padding: '6px 15px'}}>
                                    {t(
                                        'analytics.exchange.report.columns.organizationType'
                                    )}
                                </HCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {store.items.length > 0 &&
                                store.items.map((i) => {
                                    return (
                                        <Fragment key={i.id}>
                                            <TableRow
                                                style={{ height: 60 }}
                                                hover
                                                key={i.id}
                                            >
                                                <Cell
                                                    style={{ paddingLeft: 30 }}
                                                >
                                                    <Typography
                                                        variant="subtitle2"
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        {`${i.feedId!.substring(
                                                            0,
                                                            11
                                                        )}...`}
                                                        <CopyToClipboard
                                                            copyText={i.feedId!}
                                                        />
                                                    </Typography>
                                                </Cell>
                                                <Cell>
                                                    <Typography variant="subtitle2">
                                                        {i.clientName}
                                                    </Typography>
                                                </Cell>
                                                <Cell>
                                                    <Typography variant="subtitle2">
                                                        {t(
                                                            'common.' +
                                                                i.entityType?.toLowerCase()
                                                        )}
                                                    </Typography>
                                                </Cell>
                                                <Cell
                                                    style={{
                                                        paddingRight: 30,
                                                        marginRight: 10,
                                                    }}
                                                >
                                                    <NavLink
                                                        to={`/${
                                                            i.entityType?.toLowerCase() +
                                                            's'
                                                        }/${i.entityId}`}
                                                        target="_blank"
                                                    >
                                                        {`${i.entityId!.substring(
                                                            0,
                                                            11
                                                        )}...`}
                                                    </NavLink>
                                                    <CopyToClipboard
                                                        copyText={i.entityId!}
                                                    />
                                                </Cell>
                                                <Cell>
                                                    <Typography variant="subtitle2">
                                                        {i.actionType
                                                            ? t(
                                                                  'common.' +
                                                                      i.actionType?.toLowerCase()
                                                              )
                                                            : ''}
                                                    </Typography>
                                                </Cell>
                                                <Cell>
                                                    <Typography variant="subtitle2">
                                                        {moment(
                                                            i.completedAt!
                                                        ).format(
                                                            'DD/MM/YYYY HH:mm'
                                                        )}
                                                    </Typography>
                                                </Cell>
                                                <Cell>
                                                    <SeverityPill
                                                        color={
                                                            i.status == 'Failed'
                                                                ? 'error'
                                                                : 'success'
                                                        }
                                                    >
                                                        {t(
                                                            'common.' +
                                                                i.status?.toLowerCase()
                                                        )}
                                                    </SeverityPill>
                                                </Cell>
                                                <Cell>
                                                    <Typography variant="subtitle2">
                                                        {i.specialityName}
                                                    </Typography>
                                                </Cell>
                                                <Cell style={{padding: '6px 15px'}}>
                                                    <Typography variant="subtitle2">
                                                        {i.organizationTypeName}
                                                    </Typography>
                                                </Cell>
                                            </TableRow>
                                        </Fragment>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    {!store.items.length && <EmptyBox />}
                    {store.items.length > 0 && <Pagination store={store} />}

                    <Progress store={store} />
                </Card>
            </Box>
        </AuthorizePermission>
    );
});
