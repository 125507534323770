import {observer} from "mobx-react";
import {JobStore} from "../../../../stores/management/dcr/requests/builders/fieldsStores/JobStore";
import {DefaultSelect} from "../../../../components/shared/components/DefaultSelect";
import {Box, List, Typography} from "@mui/material";
import React from "react";
import {DcrListItem} from "../../DcrListItem";
import {useTranslation} from "react-i18next";
import {DefaultCheckbox} from "../../../../components/shared/components/DefaultCheckbox";
import {SeverityPill} from "../../../../components/severity-pill";

interface Props {
    store: JobStore;
}

export const JobComponent = observer(({store}: Props) => {
    const {t} = useTranslation();

    const getReadOnlyBlock = (value: string) =>
        <div
            style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
            title={value}>
            {
                value
            }
        </div>

    return <>
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <List style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: "wrap",
                alignItems: 'flex-start',
                padding: 'unset'
            }}>
                <Typography variant="body1" style={{position: 'absolute', top: '-38px', left: '237px'}}>
                    <SeverityPill
                        color={store.label.color}>
                        {t(`dcrPage.label.${store.label.title}`)}
                    </SeverityPill>
                </Typography>
                {
                    store.visibilitySelectedJob &&
                    <DcrListItem
                        name={store.edit ? '' : `${t('dcrPage.selectedJob')} ${store.edit ? "*" : ""}`}
                        width='32%'
                        link={store.linkOrganization}
                    >
                        {
                            store.edit ? <DefaultSelect store={store.selectedJob}
                                                        hasLabel
                                                        disableClearable
                                                        link={store.linkOrganization}
                                                        title={t('dcrPage.selectedJob')}
                                                        nameExp={store.selectedJob.nameExp}
                                                        valueExp="id"/> : getReadOnlyBlock(store.selectedJob.readValue)
                        }

                    </DcrListItem>
                }
                {
                    store.visibilityNewJob &&
                    <>
                        <DcrListItem
                            name={store.edit ? '' : `${t('dcrPage.fields.company')} ${store.edit ? "*" : ""}`}
                            link={store.linkOrganization}
                            width='32%'
                        >
                            {
                                store.edit ? <DefaultSelect store={store.organization}
                                                            hasLabel
                                                            title={t('dcrPage.fields.company')}
                                                            link={store.linkOrganization}
                                                            nameExp={store.organization.nameExp}
                                                            valueExp="id"/>
                                    : getReadOnlyBlock(store.organization.readValue)
                            }
                        </DcrListItem>
                    </>
                }
                {!store.store._store._store.isCompletedStatus && <DcrListItem
                    name={store.edit ? '' : `${t('dcrPage.fields.position')} ${store.edit ? "*" : ""}`}
                    width='32%'>
                    {
                        store.edit ? <DefaultSelect store={store.position}
                                                    hasLabel
                                                    title={t('dcrPage.fields.position')}
                                                    nameExp={store.position.nameExp}
                                                    valueExp="id"/>
                            : getReadOnlyBlock(store.position.readValue)
                    }
                </DcrListItem>}
                <DcrListItem
                    name={store.edit ? '' : `${t('dcrPage.jobStatus')} ${store.edit ? "*" : ""}`}
                    width='32%'>
                    {
                        store.edit ? <DefaultSelect store={store.jobStatus}
                                                    hasLabel
                                                    title={t('dcrPage.jobStatus')}/>
                            : getReadOnlyBlock(store.jobStatus.readValue)
                    }
                </DcrListItem>
                <DcrListItem
                    name={store.edit ? '' : `${t('dcrPage.fields.isPrimary')} ${store.edit ? "*" : ""}`}
                    width='32%'
                >
                    {
                        store.edit ? <DefaultCheckbox store={store.isPrimary}
                                                      disabled={store.isPrimary.disabled}
                                                      title={t('dcrPage.fields.isPrimary')}/>
                            : getReadOnlyBlock(store.isPrimary.readValue)
                    }
                </DcrListItem>
                {
                    store.visibilityPrimaryJob &&
                    <DcrListItem
                        name={store.edit ? '' : `${t('dcrPage.activeJob')} ${store.edit ? "*" : ""}`}
                        width='32%'
                    >
                        {
                            store.edit ?
                                <DefaultSelect
                                    hasLabel
                                    store={store.primaryJob}
                                    title={t('dcrPage.activeJob')}
                                    nameExp={store.primaryJob.nameExp}
                                    valueExp="id"/> : getReadOnlyBlock(store.primaryJob.readValue)
                        }
                    </DcrListItem>
                }
            </List>
        </Box>
    </>
})

