import {Box, Card, Table, TableBody, TableHead, TableRow, Tooltip, Typography} from "@mui/material";
import {observer} from "mobx-react";
import {Fragment, useState} from 'react';
import {Cell, EmptyBox, HCell, Page, PaginationLocal, Progress,} from '../../../../components';
import {useTranslation} from "react-i18next";
import {Scrollbar} from "../../../../scrollbar";
import {SeverityPill, SeverityPillColor} from "../../../../components/severity-pill";
import {personStatusHandler} from "../../Persons";
import {NavLink} from "../../../../components/NavLink";
import {CopyToClipboard} from "../../../../components/CopyToClipboard";
import {JobDoubleTableStore} from "../../../../stores/management/persons/tabs/jobs/jobDouble/JobDoubleTableStore";

interface Props {
    store: JobDoubleTableStore;
    double?: boolean;
    style?: React.CSSProperties;
}

export const JobDoubleTable = observer(({ store, double = false, style }: Props) => {
    const { t } = useTranslation();
    const [openDetails, setOpenDetails] = useState<string | null>(null);

    const handleOpenDetails = (productId: string): void => {
        setOpenDetails((prevValue) => (prevValue === productId ? null : productId));
    };

    return (
        <>
            <Page style={style}>
                <Card sx={{ mt: 1 }}>
                    <Scrollbar>
                        <Table sx={{ minWidth: 1050 }}>
                            <TableHead>
                                <TableRow>
                                    <HCell width='2%' testid="jobDouble-expand"></HCell>
                                    <HCell orderBy='id' store={store} width='10%' testid="jobDouble-Id">Id</HCell>
                                    <HCell orderBy='organizationName' store={store} width='15%' testid="jobDouble-organizationName">{t('table.organization')}</HCell>
                                    <HCell orderBy='orgType' store={store} width='12%' testid="jobDouble-orgType">{t('table.type')}</HCell>
                                    <HCell orderBy='post' width='12%' store={store} testid="jobDouble-post">{t('table.post')}</HCell>
                                    <HCell orderBy='status' width='10%' store={store} testid="jobDouble-status">{t('table.status')}</HCell>
                                    <HCell orderBy='createdDate' width='9%' store={store} testid="jobDouble-create">{t('table.createdDate')}</HCell>
                                    <HCell orderBy='modified' store={store} width='11%' testid="jobDouble-modified">{t('table.modified')}</HCell>
                                    <HCell orderBy='address' width='15%' store={store} testid="jobDouble-address">{t('table.address')}</HCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    store.items.length > 0
                                        ? store.items.map(item => {
                                            const open = item.id === openDetails;
                                            return (
                                                <Fragment key={item.id}>
                                                    <TableRow
                                                        hover
                                                        key={item.id}
                                                    >
                                                        <Cell testid="jobDouble-expand" style={{ height: 60 }} />
                                                        <Cell testid="jobDouble-id">
                                                            <Typography variant="subtitle2"
                                                                style={{ display: "flex", alignItems: 'center' }}>
                                                                {`${item.dto.organization.id.substring(0, 11)}...`}
                                                                <CopyToClipboard copyText={item.dto.organization.id}/>
                                                            </Typography>
                                                        </Cell>
                                                        <Cell style={{ maxWidth: 150 }} titleText={item.organizationName} testid="jobDouble-organizationName">
                                                            <NavLink to={`/organizations/${item.dto.organization.id}`}
                                                                     target="_blank">
                                                                <Tooltip title={item.organizationName}>
                                                                    <Typography variant="subtitle2"
                                                                        style={{
                                                                            maxWidth: 150,
                                                                            display: '-webkit-box',
                                                                            WebkitLineClamp: 2,
                                                                            WebkitBoxOrient: 'vertical',
                                                                            overflow: 'hidden'
                                                                        }}
                                                                    >
                                                                        {item.organizationName}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </NavLink>
                                                        </Cell>
                                                        <Cell testid="jobDouble-orgType">
                                                            {item.orgType}
                                                        </Cell>
                                                        <Cell titleText={item.post} testid="jobDouble-post">
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body2">
                                                                {item.post}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell testid="jobDouble-status">
                                                            {("status" in item.dto) &&
                                                                <SeverityPill
                                                                    color={personStatusHandler(item.status || '') as SeverityPillColor}>
                                                                    <Tooltip
                                                                        title={item.status ? item.status : 'Работает'}>
                                                                        <Typography variant="caption" align={'left'} sx={{
                                                                            maxWidth: 130,
                                                                            textOverflow: 'ellipsis',
                                                                            overflow: 'hidden',
                                                                        }}>
                                                                            {item.status}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                </SeverityPill>
                                                            }
                                                        </Cell>
                                                        <Cell testid="jobDouble-create">
                                                            {item.createDate}
                                                        </Cell>
                                                        <Cell testid="jobDouble-modified">
                                                            {item.date}
                                                        </Cell>
                                                        <Cell titleText={item.address} testid="jobDouble-address">
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body2">
                                                                {item.address}
                                                            </Typography>
                                                        </Cell>
                                                    </TableRow>
                                                    {/* {
                                                        open && (
                                                            <ExpandRow key={`${item.id}_`} item={item}>
                                                                <JobDetails job={item} />
                                                            </ExpandRow>)
                                                    } */}
                                                </Fragment>)
                                        })
                                        : <Cell colSpan={9}>
                                            <EmptyBox />
                                        </Cell>
                                }
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </Card>
                {store.items.length > 0 && <PaginationLocal store={store} />}
                {!store.items.length && !!store.loading && <Box
                    sx={{
                        flexGrow: 1,
                        py: 3,
                    }}
                >
                    <Progress store={store} />
                </Box>
                }
            </Page>
        </>
    );
});
