import type {ApexOptions} from 'apexcharts';
import Box from '@mui/material/Box';
import {observer} from 'mobx-react';
import {useTheme} from '@mui/material/styles';
import React from "react";
import {Chart} from "../chart";

const useChartOptions = (categories: string[]): ApexOptions => {
    const theme = useTheme();

    return {
        legend: {
            horizontalAlign: 'right',
            labels: {
                colors: theme.palette.text.secondary
            },
            position: 'top',
            show: true
        },
        chart: {
            background: 'transparent',
            stacked: false,
            toolbar: {
                show: false
            }
        },
        colors: [
            theme.palette.primary.main,
            theme.palette.warning.main,
        ],
        grid: {
            borderColor: theme.palette.divider,
            strokeDashArray: 2,
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        markers: {
            hover: {
                size: undefined,
                sizeOffset: 2
            },
            radius: 2,
            shape: 'circle',
            size: 4,
            strokeWidth: 0
        },
        stroke: {
            curve: 'smooth',
            lineCap: 'butt',
            width: 3
        },
        theme: {
            mode: theme.palette.mode
        },
        xaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: true
            },
          //  tickPlacement: 'between',
            categories: categories,
            min: 1,
            max: categories.length,
            labels: {
                style: {
                    colors: theme.palette.text.secondary
                }
            }
        },
        yaxis: [

        ]
    };
};

export interface LineChartData {
    data: (number | null)[];
    name: string;
}

export interface LineChartProps {
    data: LineChartData[];
    periods: string[]
}

export const LineChart = observer((props: LineChartProps) => {
    const chartOptions = useChartOptions(props.periods);
    return (
        <Box>
            <Chart
                height={350}
                options={chartOptions}
                series={props.data}
                type="line"
            />
        </Box>
    );
});